  <template>
	<b-row class="row-section-items">
		<b-col class="col-12 col-sm-6 col-md-3 mc-section-item" v-for="(item, indexSection) in sectionItems" :key="indexSection">
                     <b-card class="my-3 text-center mc-section-item-card">
                     	<img :src="item.image.big" alt="" lazy v-if="item.image.big!=null" class="img-fluid mc-item-img">
                        <h4 class="mc-section-item-title" v-html="item.title"></h4>
                        <h5 class="mc-section-item-price">$ <span v-html="item.price"></span></h5>
                        <b-card-text class="mc-section-item-desc" >
                        	<div v-html="item.desc"></div>
                        </b-card-text>
                     </b-card>
        </b-col>
	</b-row>
</template>	


 <script>
 	export default {
    name: 'MenuSectionItem',
    props:{
    	sectionItems: Array
    },
    components: { 
    
     },
     data() {
      return {
  
      }
    },
    methods:{
       
    },
   mounted(){
     	 
     	 
  }
}
 </script>

<style lang="less">
 
</style>

