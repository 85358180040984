<template>
    <div class="strap  py-5 " id="menucheck-menu">
        <section v-show="showMenus===true" id="mc-menu-list">
            <div class="loader-spinner" v-if="showSpinner">
                <div class="d-flex justify-content-center mb-3">
                    <b-spinner style="width: 3rem; height: 3rem; color:white;" id="spinner"></b-spinner>
                </div>
            </div>
            <b-container>
                <b-row class="menu-row my-3 " v-if="showSpinner==0">
                    <b-col class="col-12 col-sm-6 col-md-3 mc-menu-item " v-for="(menu, index) in Menus" :key="index">
                        <b-card tag="article" style="max-width: 100%" 
                        :img-src="menu.menu_images.big"
                        img-alt="Image"
                        img-top
                        class="mb-4 text-center mc-menu-card ">
                            <h2 class="mc-menu-title my-3" v-html="menu.title"></h2>
                            <b-button class="text-uppercase btn btn-sm" href="#" @click="getMenu(index,menu);">View Menu</b-button>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <transition name="fade">
            <section v-show="showNavSections===true" id="mc-header" :class="{ 'sticky': isSticky }" ref="header">
                <b-container fluid>
                    <b-row>
                        <b-col class="col-6 col-sm-6 col-md-6">
                            <b-button @click="backMenu" class="btn  ">
                                <small>Back</small>
                            </b-button>
                        </b-col>
                        <b-col class="col-6 col-sm-6 col-md-6 d-flex flex-row-reverse bd-highlight">
                            <b-dropdown id="dropdown-menu" text="Menu" class="m-md-2 align-self-end btn   btn-sm  ">
                                <b-dropdown-item v-for="(menu, index1) in Menus" :key="index1" @click="getMenuClick(index1,menu);">
                                    <span v-html="menu.title"></span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-12 my-3">
                            <h1 class="mc-menu-title" v-html="MenuTitle"></h1>
                            <div class="mc-menu-desc" v-html="MenuDesc"></div>
                        </b-col>
                    </b-row>
                    <!--Menu-->
                    <!--Navbar menu-->
                    <b-row class="row-nav my-3" id="mc-navbar">
                        <b-col>
                            <b-navbar class="navbar-nav-scroll mc-navbar  ">
                                <b-navbar-nav class="menu-navbar">
                                    <b-nav-item v-for="(section, index1) in MenuSections" :key="index1" 
                                    @click="getSection(section)" class="text-center mx-1 btn   btn-sm menu-navbar-item mc-navbar-item">
                                        <span v-html="section.title"></span>
                                    </b-nav-item>
                                </b-navbar-nav>
                            </b-navbar>
                        </b-col>
                    </b-row>
                    <!--/Navbar menu-->
                </b-container>
            </section>
        </transition>
        <transition name="fade">
            <section class="container" id="mc-sections">
                <div class="loader-spinner" v-if="showSpinner">
                    <div class="d-flex justify-content-center mb-3">
                        <b-spinner style="width: 3rem; height: 3rem; color:white;" id="spinner"></b-spinner>
                    </div>
                </div>
                <!--All sections-->
                <!--section-->
                <b-row class="row-sections  mc-section  my-3" v-for="(section, index2) in MenuSections" 
                :key="index2" v-if="showSpinner==false" v-show="showAllSections===true">
                    <b-col>
                        <!--section-title-->
                        <b-row class="row-section   my-3">
                            <div class="col-12 col-md-8 text-center mx-auto">
                                <h2 class="text-center  mc-section-title" v-html="section.title"> </h2>
                                <div class="mc-section-desc" v-html="section.desc"></div>
                            </div>
                        </b-row>
                        <!--/section-title-->
                        <!--section-items-->
                        <MenuSectionItem :sectionItems="section.items" v-animate-onscroll="'animated flip'" 
                        data-animate-onscroll-offset="100" />
                        <!--/section-items-->
                    </b-col>
                </b-row>
                <!--/section-->
                <b-row fluid v-show="showSelectedSection===true" v-if="showSpinner==false">
                    <b-col>
                        <b-row class="row-section  my-3">
                            <div class="col-12 col-md-8 text-center mx-auto">
                                <h2 class="text-center mc-section-title " v-html="sectionTitle"> </h2>
                                <div class="mc-section-desc" v-html="sectionDesc"></div>
                            </div>
                        </b-row>
                        <MenuSectionItem :sectionItems="MenuSectionItems" />
                    </b-col>
                </b-row>
            </section>
        </transition>
    </div>
</template>
<script>
// @ is an alias to /src


import MenuSectionItems from '@/modules/menu/MenuSectionItems.vue';
import MenuSectionItem from '@/modules/menu/MenuSectionItem.vue';
export default {
    name: 'MenuDefault',
    props: {

    },
    components: {
        MenuSectionItems,
        MenuSectionItem

    },
    data() {
        return {
            MenuTitle: '',
            MenuDesc: '',
            Menus: [],
            MenuSections: [],
            Sections: [],
            Items: [],
            showMenus: true,
            showSections: false,
            showSectionsItems: false,
            showAllSections: false,
            showSelectedSection: false,
            showNavSections: false,
            sectionTitle: '',
            sectionDesc: '',
            MenuSectionItems: [],
            showSpinner: false,
            isSticky: false,
            stickyOffset: 0,
            stickyOffset: 0,
            lastScrollTop: 0,
        }
    },
    methods: {
        backMenu() {
            this.showPreload('mc-menu-list');
            this.showSections = false;
            this.showMenus = true;
            this.showAllSections = false;
            this.showSelectedSection = false;
            this.showNavSections = false;
            this.showPreload('mc-menu-list');

        },
        getSection(section) {
            this.sectionTitle = section.title;
            this.sectionDesc = section.section_desc;
            this.MenuSectionItems = section.items;
            this.showPreload();
            this.showSections = false;
            this.showSectionsItems = true;
            this.showAllSections = false;
            this.showSelectedSection = true;
            this.showPreload('mc-sections');



        },

        getMenu(index, menu) {
            this.showNavSections = true;
            this.showAllSections = true;
            this.MenuSections = menu.sections;
            this.MenuTitle = menu.title;
            this.MenuDesc = menu.menu_desc;
            //  this.showPreload();
            this.showMenus = false;
            this.showPreload('mc-sections');


        },

        getMenuClick(index, menu) {
            this.showNavSections = true;
            this.showAllSections = true;
            this.MenuSections = menu.sections;
            this.MenuTitle = menu.title;
            this.MenuDesc = menu.menu_desc;
            //  this.showPreload();
            this.showMenus = false;

            this.showPreload('mc-sections');

        },
        showPreload(section = '', time = 500) {
            this.showSpinner = true;
            setTimeout(() => {
                this.showSpinner = false;
                this.scrollToElement(section, time);
            }, 750);
        },
        showModal() {
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
        toggleModal() {
            // We pass the ID of the button that we want to return focus to
            // when the modal has hidden
            this.$refs['my-modal'].toggle('#toggle-btn')
        },
        handleScroll() {
            const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (currentScrollTop > this.lastScrollTop) {
                // Scrolling down
                this.isSticky = currentScrollTop > this.stickyOffset;
            } else {
                // Scrolling up
                this.isSticky = false;
            }

            this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;

        },
        scrollToElement(elementId = 'menucheck-menu', duration = 1000) {
            const element = document.getElementById(elementId);

            if (element) {
                const startPosition = window.scrollY;
                const targetPosition = parseInt(element.offsetTop - 460);
                const distance = targetPosition - startPosition;
                const startTime = performance.now();

                function scrollStep(timestamp) {
                    const timeElapsed = timestamp - startTime;
                    const progress = Math.min(timeElapsed / duration, 1);

                    window.scrollTo(0, startPosition + distance * progress);

                    if (timeElapsed < duration) {
                        requestAnimationFrame(scrollStep);
                    }
                }

                requestAnimationFrame(scrollStep);
            }
        },
        async getConfig() {
            try {
                const response = await fetch('https://menucheck.us/restaurants/api/el-semental-sport-bar');

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const websiteJson = await response.json();
                window.localStorage.setItem('websiteData', JSON.stringify(websiteJson));
                this.website = JSON.parse(window.localStorage.getItem('websiteData'));
                this.Menus = this.website.bussines_menus;

                if (this.Menus.length > 0) {
                    this.showPreload();
                }

                console.log(this.Menus);
            } catch (error) {
                console.error('Error fetching site data:', error);
            }
        },
    },
    destroyed() {
        // Remove the scroll event listener when the component is destroyed
        window.removeEventListener("scroll", this.handleScroll);
    },
    created() {

    },
    mounted() {


        this.getConfig();
        // Get the header element
        this.header = this.$refs.header;
        this.stickyOffset = this.header.offsetTop;
        window.addEventListener("scroll", this.handleScroll);
    }
}
</script>
<style lang="less">
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;

    /* Add any other styles you need for the sticky header */
    .mc-menu-title {
        //  display:none;
    }

    background:rgba(32,32, 32, .90);
    margin-bottom:140px;
}



#menucheck-menu {
    background: #1B1424;

    #mc-menu-list {
        .mc-menu-item {
            margin-bottom: 1rem;

            .mc-menu-card {
                height: 100%;
                box-shadow: 9px 9px 9px rgba(0, 0, 0, .50);
                background: rgb(231, 53, 56);
                background: linear-gradient(198deg, rgba(231, 53, 56, 0.4) 0%, rgba(94, 35, 52, 0.6) 48%, rgba(38, 27, 51, 0.8) 100%);

                .mc-menu-title {
                    color: #FFF;
                    font-size: 1.6rem;
                    text-transform: uppercase;
                }

                .mc-menu-desc {}

                .btn {
                    background: #E73538;
                    border-color: #E73538;
                    border:none;
                }
            }
        }
    }



    #mc-header {

      .btn{
          background: rgb(231, 53, 56);
      }
        #mc-navbar {
            background: linear-gradient(198deg, rgba(231, 53, 56, 0.4) 0%, rgba(94, 35, 52, 0.6) 48%, rgba(38, 27, 51, 0.8) 100%);

            .mc-navbar-item {
                background: #E73538;
                border-color: #E73538;
                width: fit-content !important;
                min-width: fit-content !important;

                .nav-link {
                    color: #FFF;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.2rem;

                }
            }

        }

        #dropdown-menu{
           a{
            background: #E73538;
           }

           .dropdown-menu{
               background: #E73538;
               a{
                color:#FFF;
                font-weight:bold;
               }
           }  
        }

        .mc-menu-title {
            color: #FFF;
            font-size: 1.6rem;
            text-transform: uppercase;
        }

        .mc-menu-desc {
            color: #FFF;
        }
    }


    #mc-sections {

        .mc-section-item-card {
            background: rgb(53, 56, 231);
            background: linear-gradient(191deg, rgba(53, 56, 231, 0.2) 0%, rgba(57, 35, 94, 0.2) 48%, rgba(38, 27, 51, 0.2) 100%);

            .mc-section-item-title {
                color: #FFF;
            }

            .mc-section-item-price {
                color: #E73538;
            }

            .mc-section-item-desc {
                color: #FFF;
            }
        }


        .mc-section-title {
            color: #FFF;
            font-size:2.2rem!important;
        }

        .mc-section-desc {
            color: #FFF;
        }
    }


}
</style>