<template>
  		  
  					<Navbar2 />
   
</template>
<script>

 import Navbar2 from  '@/straps/nav/Navbar2'
export default {
  name: 'Header1',
  components:{
  	Navbar2
  },
  props: {
    
  }
}
</script>