<template>
	<!--section-->
  		<section class="strap  strap-cta-1"> 	 
		 	 <div class="inner py-5">
		 	 	<b-container>                 
				     <!--row-->
                  <b-row class="row justify-content-center">
                        <b-col class="col-12 col-lg-8 col-xl-6 text-center">
                              
                              <h2 class="h1">Check Our Menu</h2>
                            
                              <p class="h3 mt-5">
                                    <a href="#" class="btn btn-primary">Check Our Menu</a>
                              </p>
                              
                        </b-col>
                  </b-row>
                  <!-- /row -->
				</b-container>   
			 </div>
	 </section> 
	 <!--/section-->	 
 
</template>

<script>
// @ is an alias to /src
 
export default {
  name: 'StrapCta1',
    components: { 

     },
	 data(){
		return{
			 configSite:[],
			 

		}
	 },
	 mounted(){
	  	this.configSite =  JSON.parse(window.localStorage.getItem('siteDataJson')); //
	  	 
	 }
}
</script>
