<template>
	<div>
		 
          <MenuSectionItem :sectionItems="sectionItems" />
	</div>
</template>	


 <script>
 	import MenuSectionItem from '@/modules/menu/MenuSectionItem.vue';

 	export default {
    name: 'MenuSectionItems',
    props:{
    	section: Object
    },
    components: { 
    	MenuSectionItem
     },
     data() {
      return {
       	  sectionTitle:'',
       	  sectionDesc:'',
       	  sectionItems:[]
      }
    },
    methods:{
       
    },
   mounted(){
     	this.sectionTitle=this.section.title;
     	this.sectionDesc=this.section.section_desc;
     	this.sectionItems=this.section.items;
  }
}
 </script>

<style lang="less">
 
</style>

