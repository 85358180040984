<template>
     <footer class="footer">
            <b-container>
                  <b-row>
                        <b-col md="6" class="mx-auto text-center">
                              <ul class="nav">
                                   <li>
                                         <a href="#">About US</a>
                                   </li>
                                    <li>
                                         <a href="#">Contact</a>
                                   </li>
                                   <li>
                                       <a href="#">
                                               <i class="fa fa-facebook-square "></i>
                                        </a>
                                   </li>
                                   <li>
                                         <a href="#">
                                                <i class="fa fa-instagram "></i>
                                          </a>
                                         
                                   </li>
                              </ul>
                        </b-col>
                  </b-row>
            </b-container>
      </footer> 
</template>

<script>
// @ is an alias to /src
 
export default {
  name: 'Footer1',
	 props:{

	  },
     components: { 

     },
	 data(){
		return{
		 

		}
	 },
	 methods:{

	 },
	 mounted(){
	  	 
	  	 
	 }
}
</script>
