import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    siteConfig:null
  },
  getters: {
  },
  mutations: {
     SITE_CONFIG(state, nuevosDatos) {
      state.siteConfig = nuevosDatos;
    },
  },
  actions: {
    siteConfig({ commit }, { self }) {
      try {
        const response =   fetch('http://menucheck.pw.com/restaurants/api/demo'); // Reemplaza con la URL de tus datos
        const nuevosDatos =   response.json();
        
        commit("SITE_CONFIG", nuevosDatos);
        
 
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }
  },
  modules: {
  }
})


