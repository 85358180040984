<template>
  <div class="stra-hero">
 		<div class="inner py-5">
 			<b-container class="container-fluid text-center">

			  <b-jumbotron class="py-5" >
			    
			    		 <ImagePlaceholder  :sizes="'200x200'" ></ImagePlaceholder>
 					<h3 class="display-1">The sea flavor mexican paradise</h3>
 					<p class="lead">
 						We Only Serve Sea food with Mexican style, The steak you will always  
 					</p>
 					<p><b-button class="btn btn-lg">Check our Menu</b-button></p>
			  </b-jumbotron>
			 
 			 
 		 
 		</b-container>
 		</div>
  </div>
</template>


<script>
// @ is an alias to /src
 
export default {
  	name: 'StrapHero1',
	props:{
	  	 
	  },
    components: { 

     },
	 data(){
		return{
			 configSite:[],
			 

		}
	 },
	 mounted(){
	  	this.configSite =  JSON.parse(window.localStorage.getItem('siteDataJson')); //
	  	 
	 }
}
</script>
