<template>
  <section class="stra-carousel-1">
 		<div class="inner py-5">
 			<b-container class="container-fluid">
 				<b-row>
 					 <carousel
 				 	  v-if="carouselData.length"
 				 	  :responsive="{0:{items:3,nav:false,dots:false},600:{items:5,nav:false,dots:true}}">
 				 	 	 <b-col    v-for="(item, index) in carouselData" :key="index" >
 				 	 	 	<div class="m-1"><ImagePlaceholder  :sizes="item.img" ></ImagePlaceholder></div>
 				 	 	 </b-col>
					</carousel>
 				</b-row>
 		   	</b-container>
 		</div>
  </section>
</template>


<script>
// @ is an alias to /src
 
export default {
  	name: 'StrapCarousel1',
	props:{
	  	 
	  },
    components: { 

     },
	 data(){
		return{
			 configSite:[],
			 carouselData:[
			 	{
			 		text:'Promocion 1',
			 		img:'400x401'
			 	},
			 	{
			 		text:'Promocion 2',
			 		img:'400x401'
			 	},
			 	{
			 		text:'Promocion 3',
			 		img:'400x401'
			 	},
			 	{
			 		text:'Promocion 4',
			 		img:'400x401'
			 	}
			 ]
			 

		}
	 },
	 mounted(){
	  	this.configSite =  JSON.parse(window.localStorage.getItem('siteDataJson')); //
	  	 
	 }
}
</script>
