<template>
	<!--section-->
  		<section class="strap strap-about-1"> 	 
		 	 <div class="inner py-5">
		 	 	<b-container fluid>                 
				                <b-row class="row align-items-center no-gutters">
				                        <b-col class="col-12 col-sm-12 col-md-6">
				                              <b-col class=" pr-5 pl-5">
				                                    <h3>Lorem ipsum dolor sit amet-</h3>
				                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur fuga beatae illum vitae suscipit itaque non totam ex similique voluptatum cupiditate, inventore dolore mollitia, eveniet, harum voluptates natus esse ab!</p>
				                              </b-col>
				                        </b-col>
				                        <b-col class="col-12 col-sm-12 col-md-6">
				                              <ImagePlaceholder :sizes="'800x600'" />
				                        </b-col>
				                  </b-row>
				                  <b-row class="row align-items-center no-gutters">
				                      
				                      	<b-col class="col-12 col-sm-12 col-md-6">
				                             <ImagePlaceholder :sizes="'800x600'" />
				                        </b-col>
				                          <b-col class="col-12 col-sm-12 col-md-6">
				                              <b-col class=" pr-5 pl-5">
				                                    <h3>Lorem ipsum dolor sit amet-</h3>
				                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur fuga beatae illum vitae suscipit itaque non totam ex similique voluptatum cupiditate, inventore dolore mollitia, eveniet, harum voluptates natus esse ab!</p>
				                              </b-col>
				                        </b-col>
				                  </b-row>
				            </b-container>
				         
				      </div>
	 </section> 
	 <!--/section-->	 
 
</template>

<script>
// @ is an alias to /src
 
export default {
  name: 'StrapAbout1',
	 props:{

	  },
     components: { 

     },
	 data(){
		return{
			 configSite:[],
			 

		}
	 },
	 methods:{

	 },
	 mounted(){
	  	this.configSite =  JSON.parse(window.localStorage.getItem('siteDataJson')); //
	  	 
	 }
}
</script>
