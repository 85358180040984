<template>
  <div>
    <b-navbar id="navbar2" toggleable="md"  >
      <b-navbar-brand class="d-md-none">
        <img class="navbar-logo" :src="require('@/assets/img/'+logo)" alt="El SEMENTAL SportBar">
      </b-navbar-brand>
      <b-navbar-toggle target="collapse-area"></b-navbar-toggle>
      <b-collapse id="collapse-area" is-nav>
        <b-navbar-nav>
          
        </b-navbar-nav>
        <b-navbar-nav class="d-none d-md-block mx-auto">
          <b-nav-text>
            <img class="navbar-logo" :src="require('@/assets/img/'+logo)" alt="El SEMENTAL SportBar" style="width:120px;">
          </b-nav-text>
        </b-navbar-nav>
        <b-navbar-nav class="navbar-inverse">
            <b-nav-item href="#">ABOUT US</b-nav-item>
            <b-nav-item href="#">CONTACT US</b-nav-item>
            <b-nav-item href="#"> <i class="fa fa-facebook-square "></i></b-nav-item>
            <b-nav-item href="#"> <i class="fa fa-instagram "></i></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Navbar2',
  data(){
    return{
       logo:'logo.png'
    }
   },
   mounted(){
     
   }
};
</script>

<style scoped>

</style>