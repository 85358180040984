<template>
  <section class="strap-hero-slider-1">
 		<div class="inner">
              <carousel
 				 	  v-if="sliderList.length"
 				 	  :responsive="{0:{items:1,nav:false,dots:false},600:{items:1,nav:false,dots:false}}">
 				 	 	 <b-col    v-for="(item, index) in sliderList" :key="index" >
 				 	 	 	 <img :src="require('@/assets/img/'+item.img)" alt="" style="max-height: 600px;">
 				 	 	 </b-col>
			 </carousel>
 		</div>
  </section>
</template>


<script>
// @ is an alias to /src
 
export default {
  	name: 'StrapHeroSlider1',
	props:{
	  	 
	  },
    components: { 

     },
	 data(){
		return{
			 configSite:[],
			  sliderList:[
			 	{
			 		text:' ',
			 		img:'banner1.jpg'
			 	},
			 	{
			 		text:' ',
			 		img:'banner1.jpg'
			 	},
			 	 
			 ]
			 

		}
	 },
	 mounted(){
	  	 
	  	 
	 }
}
</script>
