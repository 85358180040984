<template>
	<!--section-->
  		<section class="strap  strap-location-1"> 	 
		 	 <div class="inner py-5">
		 	 	<b-container>                 
				     <b-row>
				     	<b-col>
				     	  <iframe :src="'https://www.google.com/maps?q='+location+'&output=embed'" style="width:100%; height:auto; min-height: 380px;"></iframe> 
							
				     	</b-col>
				     </b-row>
				           
			   </b-container>     
			 </div>
	 </section> 
	 <!--/section-->	 
 
</template>

<script>
// @ is an alias to /src
 
export default {
  	name: 'StrapLocation1',
	props:{
	  	 location : String
	  },
    components: { 

     },
	 data(){
		return{
			 configSite:[],
			 

		}
	 },
	 mounted(){
	  	this.configSite =  JSON.parse(window.localStorage.getItem('siteDataJson')); //
	  	 
	 }
}
</script>
