<template>
	<!--section-->
  		<section class="strap  strap-contact-1"> 	 
		 	 <div class="inner py-5">
		 	 	<b-container>                 
				     <!--row-->
                  <div class="row mt-5 align-items-center ">
                        <div class="col-12 col-md-6 ml-auto pt-5 pt-md-0">
                              <h2 class="h3">Contact Us</h2>
                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                              <form>
                                    <div class="row">
                                          <div class="col">
                                                <input type="text" class="form-control" placeholder="Name">
                                          </div>
                                    </div>
                                    <div class="row mt-4">
                                          <div class="col">
                                                <input type="email" class="form-control" placeholder="Email">
                                          </div>
                                    </div>
                                    <div class="row mt-4">
                                          <div class="col">
                                                <input type="email" class="form-control" placeholder="Subject">
                                          </div>
                                    </div>
                                    <div class="row mt-4">
                                          <div class="col">
                                                <textarea class="form-control" name="message" rows="3" placeholder="Your Message">
                                                </textarea>
                                          </div>
                                    </div>
                                    <div class="row mt-4">
                                          <div class="col">
                                                <button type="submit" class="btn btn-primary">Send Form</button>
                                          </div>
                                    </div>
                              </form>
                        </div>
                        <div class="col-12 col-md-6 col-lg-5">
                              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quisquam neque assumenda aliquid aperiam consequuntur beatae, vitae, eaque officia debitis</p>
                              <div class="row row-cols-2">
                                    <div class="col">
                                          <i class="fa fa-map-marker fa-2x my-3"></i>
                                          <ul class="list-unstyled list-group">
                                                <li>
                                                      <strong>Email:</strong>hello@website.com</li>
                                                <li>
                                                      <strong>Phone:</strong>+44 123 123 1232</li>
                                                <li>
                                                      <strong>Whats app:</strong>+44 123 123 1232</li>
                                          </ul>
                                    </div>
                                    <div class="col">
                                          <address>
                                                <i class="fa fa-map-marker fa-2x my-3"></i>
                                                <p>71 Pilgrim Avenue Chevy Chase, MD 20815</p>
                                          </address>
                                    </div>
                              </div>
                              <div class="row my-3">
                                    <div class="col-12">
                                          <p class="h2">
                                                <a href="#" class="mx-2">
                                                      <i class="fa fa-facebook"></i>
                                                </a>
                                                <a href="#" class="mx-2">
                                                      <i class="fa fa-twitter"></i>
                                                </a>
                                                <a href="#" class="mx-2">
                                                      <i class="fa fa-instagram"></i>
                                                </a>
                                                <a href="#" class="mx-2">
                                                      <i class="fa fa-google"></i>
                                                </a>
                                                <a href="#" class="mx-2">
                                                      <i class="fa fa-pinterest"></i>
                                                </a>
                                          </p>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <!-- /row -->
				</b-container>   
			 </div>
	 </section> 
	 <!--/section-->	 
 
</template>

<script>
// @ is an alias to /src
 
export default {
  name: 'StrapContact1',
    components: { 

     },
	 data(){
		return{
			 configSite:[],
			 

		}
	 },
	 mounted(){
	  	this.configSite =  JSON.parse(window.localStorage.getItem('siteDataJson')); //
	  	 
	 }
}
</script>
