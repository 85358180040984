<template>
  		 <section class="--strap  --hero_20">
          <!--inner py-5-->
          <div class="--inner py-5">
                <!--container -->
                <!---block  --hero_20-->
                <div class="jumbotron jumbotron-fluid">
                      <div class="container">
                            <h1 class="display-3">Fluid jumbotron</h1>
                            <p class="lead">This is a modified jumbotron that occupies the entire horizontal space of its parent.</p>
                      </div>
                </div>
                <!---/block --hero_20-->
                <!-- /inner -->
          </div>
    </section> 
</template>
<script>

 
export default {
  name: 'Heading1',
  components:{
  	 
  },
  props: {
    
  }
}
</script>