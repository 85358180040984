<template>
  <div id="app">
   
    <Header1 />
   

   <!--sidebar-->  
     <b-sidebar
      id="sidebar-backdrop"
      title="Sidebar with backdrop"
      backdrop-variant="dark"
      backdrop
      shadow
    >
    

    </b-sidebar>
     <!--/sidebar-->  





    <router-view/>

   <Footer1 />

  </div>
</template>

 
<script>
 
import Header1  from '@/straps/header/Header1'
import Footer1  from '@/straps/footer/Footer1'
 
export default {
  name: '',
    components: { 
     
        Header1,
        Footer1
     },
     data() {
      return {
       
      }
    },
    methods:{
       
    },
   mounted(){
    
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
