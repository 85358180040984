<template>
  <section class="stra-gallery-1">
 		<div class="inner py-5">
 			<b-container class="container-fluid">
 				<b-row>
 					
						<div v-masonry="containerId" transition-duration="0.3s" item-selector=".item">
						    <div v-masonry-tile class="item" v-for="(item, index) in listData">
						       <a href="">
						       	 <ImagePlaceholder  :sizes="item.img" ></ImagePlaceholder>
						       </a>
						    </div>
						</div> 
 				</b-row>
 		   	</b-container>
 		</div>
  </section>
</template>


<script>
// @ is an alias to /src
 
export default {
  	name: 'StrapGallery1',
	props:{
	  	 
	  },
    components: { 

     },
	 data(){
		return{
			 configSite:[],
			 containerId:'',
			 listData:[
			 	{
			 		text:'Promocion 1',
			 		img:'400x401'
			 	},
			 	{
			 		text:'Promocion 2',
			 		img:'400x401'
			 	},
			 	{
			 		text:'Promocion 3',
			 		img:'400x401'
			 	},
			 	{
			 		text:'Promocion 4',
			 		img:'400x401'
			 	}
			 ]
			 

		}
	 },
	 mounted(){
	  	this.configSite =  JSON.parse(window.localStorage.getItem('siteDataJson')); //

	  	 
	 }
}
</script>
