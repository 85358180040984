<template>
  <div class="strap strap-centered " >
 		<div class="inner py-5" v-animate-onscroll="{down: 'animated flip'}">
 			<b-container fluid>
 			<b-row class="my-3">
 				<b-col class="col-md-6 mx-auto">
 					<h2 class="st-title text-center my-3">Comidas Para Ti</h2>
 					<p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veniam aperiam atque placeat qui quidem architecto sequi. Temporibus delectus quis ullam ratione nisi at ex, assumenda necessitatibus sint impedit optio similique.</p>
 				</b-col>
 			</b-row>
 			<b-row  class="my-3">
 				 <carousel
 				 	  v-if="categoriesList.length"
 				 	  :responsive="{0:{items:3,nav:false,dots:false},600:{items:3,nav:false,dots:true}}">
 				 	 	 <b-col    v-for="(item, index) in categoriesList" :key="index" >
 				 	 	 	<router-link to="/about" class="mc-btn">
 							   <ImagePlaceholder :sizes="'300x200'" />
 							 	<span>{{ item.title }}</span>
 							 </router-link>
 				 	 	 </b-col>
					</carousel>
 				 
 			</b-row>
 		</b-container>
 		</div>
  </div>
</template>

<script>
// @ is an alias to /src

 import carousel from 'vue-owl-carousel'
 export default {
  name: 'StrapMenuCategories',
    components: { 
    	carousel

     },
      data(){
		return{
			 configSite:[],
			 categoriesList:[]
		}
	 },
	 mounted(){
	  	this.configSite =  JSON.parse(window.localStorage.getItem('siteDataJson')); // 
	  	this.categoriesList =  this.configSite.bussines_menus
	 }
	 
}
</script>


<style lang="less">
 		 
</style>