import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PageHome from '@/pages/PageHome.vue'
import PageMenu from '@/pages/PageMenu.vue'
import PageMenuSection from '@/pages/PageMenuSection.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: PageHome
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/menu/',
    name: 'menu',
     component: PageMenu
   
  },
   {
    path: '/menu/section/:sID/',
    name: 'section',
     component: PageMenuSection
   
  },
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
