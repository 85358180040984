<template>
  <div class="strap strap-menu-categories">
 		<div class="inner py-3">
 		<b-container fluid>
 				<b-row>
 					<b-col>
 						<b-card no-body>
						    <b-tabs pills card  >
						      <b-tab :title="menu.title" v-for="(menu, index) in bussines_menus" :key="index" >
						      	   <div  v-if="showMenu==true">
						      	   		<b-row>
								      	   	<b-col>
								      				<h1>{{ menu.title }}</h1>
								      	   	</b-col>
								      	   </b-row>
								      		<b-row class="my-3" >
								      		
								      			<b-col class="col-12 col-md-4 col-lg-6"  v-for="(section, index1) in menu.sections" :key="index1">
								      				
								      				<div class="menu-item">

								      					<p>
								      						<a href="#" @click="showSectionItems(section, index)">
								      							 <strong>{{ section.title }}</strong>
								      						</a>
								      					</p>
								      					<!--
								      				 	 <router-link :to="'/menu/section/'+section.id+'/'" ><img src="holder.js/100x100" alt=""> 
		 												<p>
								      						 <strong>{{ section.title }}</strong>
								      					</p>
								      					</router-link>
								      				-->
								      				 
								      				</div>
								      			</b-col>
								      		</b-row>
						      	   </div>

						      	 <div v-if="showSection==true">
						      		   <b-row class="row" v-if="sectionsMenu.length > 0 "> 
							      	   		<b-col>
							      	   			 <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
							      	   		</b-col>
						      	  		</b-row>

							      	   <b-row class="row" v-if="sectionsMenu.length > 0 "> 
	 										<b-col>
							      	   			<b-row class="my-3">
							      	   				 <b-col>
							      	   				 	<h2>{{ sectionTitle }} </h2> 
							      	   				 </b-col>
							      	   			</b-row>
							      	   			<b-row>
							      	   				<b-col class="col-12 col-md-4"  v-for="(menuItem, index2) in sectionsMenu" :key="index2">
								      	   				<MenuItemComponent :menuItem="menuItem" ></MenuItemComponent>
							      	   				</b-col>
							      	   			</b-row>
							      	   		</b-col>
							      	   </b-row>
						      	</div>
						      </b-tab>
						    
						    </b-tabs>
						  </b-card>
 					</b-col> 
 				</b-row>
 			</b-container>
 		</div>
  </div>
</template>

<script>
// @ is an alias to /src
 import MenuItemComponent  from '@/components/menu/MenuItemComponent'

export default {
  name: 'StrapMenuTabsVertical',
    components: { 
     
    	MenuItemComponent,

     },
     data() {
      return {
      	 showMenu:true,
      	 showSection:false,
      	 sectionActive:[],
      	 sectionsMenu:[],
      	 sectionTitle:'',
         bussines_menus: [],
         breadcrumbs: [ ],
         configSite:[],
          show: false
      }
    },
    methods:{
    	  showSectionItems(section,index){
    	    this.sectionsMenu =  section.items
    	  	this.sectionTitle = section.title
    	  	this.showMenu= false;
    	  	this.showSection=true;
    	  	console.log(this.sectionsMenu);

    	  },
    	  getSectionList(index,menu){
    	  	  this.sectionsList = this.bussines_menus[index].sections
    	  	  this.sectionsMenu = this.bussines_menus[index].sections[0].items;
    	  	  this.sectionTitle =  this.bussines_menus[index].sections[0].title;
    	  	  this.sectionDesc=  this.bussines_menus[index].sections[0].title;
    	  	  this.sectionOpen = index;
    	  	  this.showSection=false;
    	  	   
    	  }


    },
    mounted(){
     const getConfig = async () => {
        
       
          this.configSite =  JSON.parse(window.localStorage.getItem('siteDataJson'));
          this.breadcrumbs = []
          this.bussines_menus=  this.configSite.bussines_menus;
        
 
      };

      getConfig();

    }
}
</script>
