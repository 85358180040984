<template>
  <div class="page-menu-section">
 	 	 	<div class="inner py-3">
	 			<b-container>
	 				<b-row>
	 					<b-col>
	 					</b-col>
	 				</b-row>
	 			</b-container>
	 		</div>
  </div>
</template>

<script>
// @ is an alias to /src

 

export default {
  name: 'PageMenuSection',
    components: { 
    	 
     
     },

}
</script>
