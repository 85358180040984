<template>
  <div class="strap  menu-list-vertical">
 		<div class="inner py-3">
 			<b-container fluid>
 				 <b-row class="my-3 row-title">
							   <b-col class="text-center">
							   	<h2 class="strap-title">Our Menu</h2> 
							   </b-col>
				  </b-row>
     			<b-row  class="my-3 row-sections">
     				<b-col class="col-12 text-center">
     					 
     						 <b-navbar  class="navbar-nav-scroll   ">
     						   <b-navbar-nav class="menu-navbar">
     								<b-nav-item  v-for="(menu, index) in bussines_menus" :key="index"
     							  	@click="getSectionList(index,menu)" 
                      v-if="menu.sections.length > 0" 
                      class="text-center mx-1 btn btn-primary btn-block menu-navbar-item" >{{ menu.title }}</b-nav-item>
    						    </b-navbar-nav>
    						  </b-navbar>
     					 
     				</b-col>
     			</b-row>
 
 		   <!--secciones-->
      <b-row v-if="sectionsList.length > 0">
          <b-col class="col col-md-12" v-for="(section, index1) in sectionsList" :key="index1">
             <a href="#" @click="getSectionItems(section, index1)"  class="" v-if="section.items.lenght > 0">
                       <strong> <span v-html="section.title"> </span></span></strong>
              </a>
         </b-col>
      </b-row>
      <!--/secciones-->
 	 
 			<!--items-->
 			 <b-row class="row" v-if="sectionsMenu.length > 0 "> 
				 
							<b-row class="my-3">
							   <b-col class="text-center">
							   	<h2>{{ sectionTitle }} </h2> 
							   	<p> {{ sectionDesc }}</p>
							   </b-col>
							 </b-row>
							<b-row>
								<b-col class="col-12 col-md-3"  v-for="(menuItem, index2) in sectionsMenu" :key="index2">
						        <b-card :img-src="menuItem.image.big" :img-alt="menuItem.title" img-top class="my-2 text-center">
                        <b-card-text>
                            <h3><span v-if="menuItem.price>0" class="price"> $ {{ menuItem.price }} </span></h3>
                          <h4> 
                            <span class="title">{{ menuItem.title }}</span>  
                          </h4>
                        
                           <div class="desc">
                            {{ menuItem.desc }}
                           </div>
                        </b-card-text>
                   </b-card>
                 </b-col>
				      </b-row>
				 </b-row>
 			</b-container>

<b-modal ref="my-modal" hide-footer title="Using Component Methods">
      <div class="d-block text-center">
        <h3>Hello From My Modal!</h3>
      </div>
      <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button>
      <b-button class="mt-2" variant="outline-warning" block @click="toggleModal">Toggle Me</b-button>
    </b-modal>

 		</div>
  </div>
</template>

<script>
// @ is an alias to /src
 import MenuItemComponent  from '@/components/menu/MenuItemComponent'

export default {
  name: 'StrapMenuTabsVertical',
  props :{
      
  },
    components: { 
     
    	MenuItemComponent,

     },
     data() {
      return {
      	 showMenu:true,
      	 showSection:false,
      	 sectionActive:[],
      	 sectionsMenu:[],
      	 sectionTitle:'',
      	 sectionDesc:'',
      	 sectionsList:[],
      	 sectionOpen:0,
         bussines_menus: [],
         breadcrumbs: [ ],
          show: false,
          configSite:[]
      }
    },
    methods:{
    	  getSectionItems(section,index){
    	  	    this.sectionsMenu =  section.items
    	  	    this.sectionTitle = section.title
    	  	    this.showMenu= false;
    	  	    this.showSection=true;
    	  
    	  },

    	  getSectionList(index,menu){
    	  	  this.sectionsList = this.bussines_menus[index].sections
    	  	  this.sectionsMenu = this.bussines_menus[index].sections[0].items;
    	  	  this.sectionTitle =  this.bussines_menus[index].sections[0].title;
    	  	  this.sectionDesc=  this.bussines_menus[index].sections[0].title;
    	  	  this.sectionOpen = index;
    	  	  this.showSection=false;
    	  	   
    	  },
          showModal() {
            this.$refs['my-modal'].show()
          },
          hideModal() {
            this.$refs['my-modal'].hide()
          },
          toggleModal() {
            // We pass the ID of the button that we want to return focus to
            // when the modal has hidden
            this.$refs['my-modal'].toggle('#toggle-btn')
          }
    },
    mounted(){
     const getConfig = async () => {


       this.configSite =  JSON.parse(window.localStorage.getItem('siteDataJson'));

       this.breadcrumbs = []
       this.bussines_menus=  this.configSite.bussines_menus;
       this.sectionsMenu = this.configSite.bussines_menus[0].sections[0].items;
       this.sectionTitle =  this.configSite.bussines_menus[0].sections[0].title;


      /*
        try {
          const response = await fetch('http://menucheck.pw.com/restaurants/api/demo');
          const siteDataJson = await response.json();
          window.localStorage.setItem('siteDataJson', JSON.stringify(siteDataJson));
          console.log(siteDataJson);

          this.breadcrumbs = []
          this.bussines_menus=  siteDataJson.bussines_menus;
          this.sectionsMenu = this.bussines_menus[0].sections[0].items;
          this.sectionTitle =  this.bussines_menus[0].sections[0].title;

        

         
      
        } catch (error) {
          console.error('Error fetching site data:', error);
        }

        */
      };

      getConfig();


    }
}
</script>

<style lang="less">
  .menu-list-vertical{
      .menu-navbar{
         
          
              .nav-item{
                white-space: nowrap;
                min-width: auto!important;
              }
       
      }
  }
</style>
