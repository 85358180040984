<template>
	<div>
		<div class="menuItem my-3">
			 <b-card :img-src="menuItem.image.big" img-alt="Card image" img-top>
				        <b-card-text>
				        	<h5> <span class="title">{{ menuItem.title }}</span>  <span v-if="menuItem.price>0" class="price"> $ {{ menuItem.price }} </span></h5>
				           <div class="desc">
				           	{{ menuItem.desc }}
				           </div>
				        </b-card-text>
			 </b-card>
		</div>
	</div>	
</template>
<script>
export default {
  name: 'MenuItemComponent',
  props: {
    menuItem: Object
  }
}
</script>