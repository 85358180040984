import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

window.$ = window.jQuery = require('jquery')

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
 


import 'animate.css';

import 'holderjs/holder.min.js'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


//lightbox
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
Vue.use(CoolLightBox)


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

//animate 

import VueAnimateOnScroll from 'vue-animate-onscroll'
Vue.use(VueAnimateOnScroll)

// import ES6 style
import {VueMasonryPlugin} from 'vue-masonry';

Vue.use(VueMasonryPlugin)

import ImagePlaceholder from  '@/components/helpers/ImagePlaceholder'
Vue.component("ImagePlaceholder", ImagePlaceholder);

import carousel  from 'vue-owl-carousel'
Vue.component("carousel", carousel);


import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import Sticky from 'vue-sticky-directive'
Vue.use(Sticky)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  carousel,
  
  render: h => h(App)
}).$mount('#app')
