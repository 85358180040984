<template>
  <div class="page-heading">
 		 <Heading1 />
 		  <StrapMenuTabsVertical />
 		   <StrapMenuListVertical />
  </div>
</template>

<script>
// @ is an alias to /src
 import Heading1 from  '@/straps/heading/Heading1'
import StrapGallery1 from  '@/straps/gallery/StrapGallery1'
import StrapHeroSlider1 from  '@/straps/hero/StrapHeroSlider1'
 import StrapHero1 from  '@/straps/hero/StrapHero1'
 import StrapAbout1 from  '@/straps/about/StrapAbout1'
 import StrapAbout2 from  '@/straps/about/StrapAbout2'
 import StrapCta1 from  '@/straps/cta/StrapCta1'
 import StrapLocation1 from  '@/straps/location/StrapLocation1'
 import StrapMenuCategories from  '@/straps/menu/StrapMenuCategories'
 import StrapContact1 from  '@/straps/forms/StrapContact1'
 import StrapMenuTabsVertical from  '@/straps/menu/StrapMenuTabsVertical'
 import StrapMenuListVertical from  '@/straps/menu/StrapMenuListVertical'
  import StrapCarousel1 from  '@/straps/carousel/StrapCarousel1'
export default {
  name: 'PageMenu',
    components: { 
    	Heading1,
    	StrapHero1,
    	StrapMenuCategories,
    	StrapMenuTabsVertical,
    	StrapMenuListVertical,
    	StrapAbout1,
    	StrapAbout2,
    	StrapCta1,
    	StrapContact1,
    	StrapLocation1,
    	StrapCarousel1,
    	StrapGallery1,
    	StrapHeroSlider1
     },
}
</script>
