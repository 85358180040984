<template>
	 <div>
	 	 
	 	 <img loading="lazy" :src="'https://via.placeholder.com/'+sizes" class="img-fluid" alt="" />
	 </div>
</template>
<script>
export default {
  name: 'ImagePlaceholder',
  props: {
    sizes: String
  }
}
</script>