<template>
  <div class="page-home">
 	 <StrapHeroSlider1 /> 
 	   <section class="strap strap-woman">
 	 	  <b-container fluid class="fullwidth">
	 	 	 	<b-row class="align-items-end">
	 	 	 		<b-col cols="12" md="6" order="2" order-md="1">
	 	 					<img :src="require(`@/assets/img/menu_btn.jpg`)" class="img-fluid" style="max-height: 280px; float:right;"  alt="">
	 	 			</b-col>
	 	 			<b-col cols="12" md="6" order="1" order-md="2">
	 	 					<img  :src="require(`@/assets/img/woman.jpg`)"   class="img-fluid" alt=""  >
	 	 			</b-col>
	 	 		</b-row>
 	 		</b-container>	 	
 	 	</section>
     <MenuDefault />
      <section class="strap strap-logo">
 	 	  <b-container fluid class="fullwidth">
	 	 	 	<b-row>
	 	 	 		<b-col cols="12" md="4" class="mx-auto text-center"  >
	 	 					<img :src="require(`@/assets/img/logo.png`)"   class="img-fluid my-4" style="max-height: 160px;"  alt="">
	 	 			</b-col>
	 	 		</b-row>
	 	 		<b-row class="my-3">
	 	 			<b-col>
	 	 				<p class="text-center"><strong>COPYRIGHT 2023 <a href="https://menucheck.us/" target="_blank">MENUCHECK.US</a> , ALL RIGHTS RESERVED</strong></p>
	 	 			</b-col>
	 	 		</b-row>
 	 		</b-container>	 	
 	 	</section>
 		   
  </div>
  
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import MenuDefault from  '@/modules/menu/MenuDefault.vue';
 import StrapGallery1 from  '@/straps/gallery/StrapGallery1'
import StrapHeroSlider1 from  '@/straps/hero/StrapHeroSlider1'
import StrapHero1 from  '@/straps/hero/StrapHero1'
import StrapAbout1 from  '@/straps/about/StrapAbout1'
import StrapAbout2 from  '@/straps/about/StrapAbout2'
import StrapCta1 from  '@/straps/cta/StrapCta1'
import StrapLocation1 from  '@/straps/location/StrapLocation1'
import StrapMenuCategories from  '@/straps/menu/StrapMenuCategories'
import StrapContact1 from  '@/straps/forms/StrapContact1'
import StrapMenuTabsVertical from  '@/straps/menu/StrapMenuTabsVertical'
import StrapMenuListVertical from  '@/straps/menu/StrapMenuListVertical'
import StrapCarousel1 from  '@/straps/carousel/StrapCarousel1'


export default {
	 computed: {
	    
	  },
  name: 'PageHome',
    components: { 
    	 MenuDefault,
    	 StrapGallery1,
    	 StrapHeroSlider1,
    	 StrapAbout1
     },
	 data(){
		return{
			 website:[], 
		}
	 },
	 mounted(){
	   
	 },
	 metaInfo: {
		    title: 'El Semental Sport BAr & Grill',
		     meta: [
		      { charset: 'utf-8' },
		      { name: 'description', content: 'foo' }
		    ]
	  }
	 

} 
</script>
